"use sctrict";
console.info('Hello world');

var result = document.querySelector('.result-container');

const menuBtns = document.querySelectorAll('.menuBtn'),
      form = document.querySelector('.form'),
      optionInput = document.getElementById('optionInput'),
      optionList = document.getElementById('choiceList'),
      btnGo = document.getElementById('go'),
      btnHome = document.querySelectorAll('.returnBtn'),
      body = document.querySelector('body');

menuBtns.forEach(btn => {
    btn.addEventListener('click', (e) => {
        let target = e.currentTarget;
        let page = target.getAttribute('data-page');
        
        // Si il s'agit d'un chois rapide, on redirige direct vers le résultats 
        if (target.classList.contains('goResult') == true) {
            body.setAttribute('data-page', 'result');

            // Récupère le nombre de possibilités et en tire un nombre au hasard
            let pos = target.getAttribute('data-page');
            let resultOption = Math.floor(Math.random() * pos) + 1;
            
            // Dans le cas ou on se trouve dans le 'oui ou non', on remplace les résultats 1 et 2 par le 'oui' ou 'non'
            if (pos == '2') {
                resultOption == 1 ? resultOption = 'Oui' : resultOption = 'Non';
            }

            // Insert la valeur dans le HTLM
            result.innerHTML = resultOption;
            body.setAttribute('data-page', 'result');
        }else {
            body.setAttribute('data-page', page);
        }
    });
});

// CUSTOM CHOICE MAKING

// Désative le bouton 'go' étant donné qu'aucun choix n'est encore rentré
disabledBtnGo(true);

//Sélectionne une réponse
const optionEl = [];
var options = [];
var optionCount = 0;
var resultOption;

//Sélectionne une réponse
form.addEventListener('submit', (e) => {

    if (body.hasAttribute('class', 'locked-goBtn' == true)) {
        disabledBtnGo(true);
    }

    //Empêche le naviguateur de recharger, récupère la valeur du input, vide le input
    e.preventDefault();
    let inputValue = optionInput.value;
    optionInput.value = "";
    
    //Si le input n'est pas vide
    if (inputValue != "") {
        //Push la valeur dans un tableau
        options.push(inputValue);

        //Affiche la valeur à l'écran
        let li = document.createElement('li');
        let btn = document.createElement('button');
        li.classList.add('btn');
        li.classList.add('choiceList-el');
        li.classList.add('addAnim');
        btn.innerHTML = inputValue;
        li.appendChild(btn);
        optionList.appendChild(li);
        optionEl.push(li);

        checkList();

        li.setAttribute('data-option', optionCount);
        optionCount++;

        li.addEventListener('animationend', (e) => {
            li.classList.remove('addAnim');
        });

        //Supprimer la valeur en cliquant dessus
        li.addEventListener('click', (e) => {
            //Delete animation
            let target = e.currentTarget;
            target.classList.add('deleteAnim');
            target.addEventListener('animationend', (e) => {
                target.remove();
                checkList();
            });

            let number = li.getAttribute('data-option');
            options[number] = 'deleted';   
            console.log(options);
        });
    }else{
        console.log('Vous devez entrer un texte');
    }
});

btnGo.addEventListener('click', (e) => {
    //Cherche sélectionne aléatoirement un mot et recommence si il s'agit de "deleted" 
    do {
        resultOption = options[Math.floor(Math.random() * options.length)];
    } while (resultOption === "deleted");

    result.innerHTML = resultOption;
    body.setAttribute('data-page', 'result');
});

btnHome.forEach(element => {
    element.addEventListener('click', (e) => {
        body.setAttribute('data-page', 'menu');
    });
});

function deleteElement(element) {
    console.log(options.length);
    var condition = 0;
    for (let i = 0; i < options.length; i++) {
        if (options[i] != "deleted") {
            condition++;
        }    
    }
    if (condition != 0) {
        disabledBtnGo(false);
    }else{
        disabledBtnGo(true);
    }
    element.remove();

};

// Désactive le boutton 'go'
function disabledBtnGo(param) {
    btnGo.disabled = param;
    param == false ? btnGo.classList.remove('btn-disabled') : btnGo.classList.add('btn-disabled');
}

// Vérifie si la liste de choix est assez fournie ou non pour désativer le boutton 'go'
function checkList() {
    optionList.childElementCount < 2 ? disabledBtnGo(true) : disabledBtnGo(false);
}




//Génère un dégradé aléatoire

var randomHue1 = randomMinMax(0,360),
    randomHue2 = (randomHue1 + 80) % 360;

var color1 = "hsl("+randomHue1+", 100%, 80%)",
    color2 = "hsl("+randomHue2+", 100%, 40%)";


let root = document.documentElement;
root.style.setProperty('--c-1', color1);
root.style.setProperty('--c-2', color2);

//source: https://gist.github.com/brunomonteiro3/27af6d18c2b0926cdd124220f83c474d
function randomMinMax(min,max){
    return Math.floor(Math.random()*(max-min+1)+min);
}